@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}
.disabledCursor { 
  cursor: default;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.custom-layout-content {
  background-color: white;
  position: relative;
  padding: 24px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.custom-placeholder-select .ant-select-selection-placeholder {
  color: black;
  opacity: 1;
}

/* Số lượng dòng cho phép  */
.number-of-line-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ant-form-item-explain.ant-form-item-explain-connected {
  display: flex;
  justify-content: space-between;
}
.hover-decoration:hover {
  text-decoration: "underline";
  color: "blue";
}

.ant-picker-input>input[disabled] {
  background: transparent;
  color: #000;
}
.table-row-close td {
  background-color: #FFF1F0;
  border-bottom: 1px solid "#ccc";
}
.present {
  background-color: rgb(183, 235, 143);
  height: 100px ;
   width: 100px;
}

.view-user td.ant-picker-cell {
  border: 0.5px solid  #135200 !important;
  margin: 20px !important;
}

.ant-picker-calendar-date-value {
  background: transparent !important;
  display: none !important;
}

.ant-picker-cell-inner.ant-picker-calendar-date {
  margin: 0px !important;
  padding: 0px !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: red !important;
  border: 2px solid red !important;
}
.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
  display: none;
}
.activeDiamond {
  background: #95DE64 !important;
  border: none;
  
}
.activeDiamond:focus {
  border: none;
  color: #000;
}